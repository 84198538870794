

.impress-margin {
  margin-left: 20px;
  margin-right: 20px;
}

.centering {
  max-width: 400px;
  margin: 0 auto;
}

.wbanner{
  margin-top: 25px;
}

.centerios {
  text-align: center;
}

.impress {
  text-decoration: underline;
  color: steelblue;
  cursor: pointer;
  position: fixed; 
  bottom: 5px;  
}

.unterstrich {
  text-decoration: underline;
  color: #f37121;
}

.valida {
  color: #f37121;
  font-weight: bold;
  font-size: 0.75em;
}

.korrekt {
  color: lightgreen;
  font-weight: bold;
}

.top-margin {
  margin-top: 200px;
}

.orders-filter {
  position: sticky;
  height: 40px;
  margin-top: 15px;
  margin-right: 10px;
  margin-left: 10px;
}

.card-title-class {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0;
  font-weight: bold;
  color: #555;
}

.filter-underlined {
  border-bottom: solid 2px;
  border-bottom-color: coral;
  font-weight: bold;
}

.bold {
  font-weight: bold;
  color: #555;
  margin-bottom: 10px;
}

.small {
  font-size: 00.75em;
}

.logos{
  margin: 80px;
  width: 30%;
}

.reihe{
  display: flex;
  flex-direction: row;
  justify-content: start;
}

/* .viz{
  visibility: hidden;
} */

.genz{
  margin-left: 20px;
  font-weight: bold;
  color: #555;
 
}

.genzg{
  margin-left: 20px;
  font-weight: bold;
  color: #85603f;
}